/* ubuntu-300 - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: local("Ubuntu Light"), local("Ubuntu-Light"),
  url("../public/static/fonts/ubuntu-v14-latin-300.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-300.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-regular - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: local("Ubuntu Regular"), local("Ubuntu-Regular"),
  url("../public/static/fonts/ubuntu-v14-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-regular.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-300italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 300;
  src: local("Ubuntu Light Italic"), local("Ubuntu-LightItalic"),
  url("../public/static/fonts/ubuntu-v14-latin-300italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-300italic.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 400;
  src: local("Ubuntu Italic"), local("Ubuntu-Italic"),
  url("../public/static/fonts/ubuntu-v14-latin-italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-italic.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-500 - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: local("Ubuntu Medium"), local("Ubuntu-Medium"),
  url("../public/static/fonts/ubuntu-v14-latin-500.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-500.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-500italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 500;
  src: local("Ubuntu Medium Italic"), local("Ubuntu-MediumItalic"),
  url("../public/static/fonts/ubuntu-v14-latin-500italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-500italic.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-700 - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: local("Ubuntu Bold"), local("Ubuntu-Bold"),
  url("../public/static/fonts/ubuntu-v14-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-700.woff") format("woff"); /* Modern Browsers */
}

/* ubuntu-700italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 700;
  src: local("Ubuntu Bold Italic"), local("Ubuntu-BoldItalic"),
  url("../public/static/fonts/ubuntu-v14-latin-700italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../public/static/fonts/ubuntu-v14-latin-700italic.woff") format("woff"); /* Modern Browsers */
}
