.item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 0 4px #eee;
}

.itemSelected {
  background-color: #f0f0f0;
  box-shadow: 0 0 4px #eee;
  margin-left: 4px;
  margin-right: 4px;
}

.itemNormal {
  margin-left: 4px;
  margin-right: 4px;
}

.itemMetaNormal {
  padding-left: 8px;
  padding-right: 8px;
}

.avatarSelected {
  outline: 2px solid #37b076;
  margin-left: 2px;
}

.avatarNormal {
  outline: 2px solid #cccccc;
  margin-left: 2px;
}
