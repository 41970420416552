/* ['#e6f7ff', '#bae7ff', '#91d5ff', '#69c0ff', '#40a9ff', '#1890ff', '#096dd9', '#0050b3', '#003a8c', '#002766', primary: '#1890ff'] */

@keyframes blink {
  0% {
    background-color: #bae7ff;
  }
  49% {
    background-color: #bae7ff;
  }
  50% {
    background-color: #69c0ff;
  }
  99% {
    background-color: #69c0ff;
  }
  100% {
    background-color: #bae7ff;
  }
}

.react-flow .missingWarning {
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 4px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
  background-color: #fefbe8;
  border: 1px solid #efb041;
}
